import React, { useEffect, useState } from 'react'

import * as AccreditationHistoricComponents from './components'
import { useNavigate } from 'react-router-dom'
import AppLayout from '../../components/app-layout/app-layout'
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ControlOutlined,
  ExclamationOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import {
  getCompany,
  getProposals,
  listMatriz,
  sendProposalInfoRequested
} from '../../providers/api/accreditations'
import { maskCpfCnpj } from '../../utils/masks'
import moment from 'moment'
import { checkLogin } from '../../providers/api/login'
import Cookies from 'js-cookies'
import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Select,
  Spin,
  message
} from 'antd'

const AccreditationHistoric = () => {
  const navigate = useNavigate()
  const [proposals, setProposals] = useState([])
  const [pagination, setPagination] = useState({ currentPage: 1, count: 0 })
  const [statusFilter, setStatusFilter] = useState(null)
  const [companyFilter, setCompanyFilter] = useState()
  const [uniqueKey, setUniqueKey] = useState([])
  const [nameFilter, setNameFilter] = useState()
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [hideenInputs, setHiddenInputs] = useState(false)
  const [requestFilter, setRequestFilter] = useState({
    page: 1
  })
  const [spin, setSpint] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const accreditationStatusData = [
    {
      status: 'processing',
      icon: <ArrowRightOutlined />,
      label: 'Em Análise',
      class: 'accreditation-status-processing'
    },
    {
      status: 'waiting-risk-team',
      icon: <ArrowRightOutlined />,
      label: 'Aguardando Análise de Risco',
      class: 'accreditation-status-processing'
    },
    {
      status: 'request-update',
      icon: <ExclamationOutlined />,
      label: 'Ajuste Solicitado',
      class: 'accreditation-status-refused'
    },
    {
      status: 'waiting-se-config',
      icon: <ControlOutlined />,
      label: 'Aguardando configuração Software Express',
      class: 'accreditation-status-waiting-se-config'
    },
    {
      status: 'approved',
      icon: <CheckCircleOutlined />,
      label: 'Aprovado',
      class: 'accreditation-status-approved'
    },
    {
      status: 'refused',
      icon: <CloseCircleOutlined />,
      label: 'Reprovado',
      class: 'accreditation-status-refused'
    },
    {
      status: 'refused-by-risk-team',
      icon: <CloseCircleOutlined />,
      label: 'Reprovado Pelo Time de Risco',
      class: 'accreditation-status-refused'
    }
  ]

  const DynamicSelectedDate = [
    {
      label: 'Ultimos 30 dias',
      value: '30 dias'
    },
    {
      label: 'Ultimos 60 dias',
      value: '60 dias'
    },
    {
      label: 'Ultimos 90 dias',
      value: '90 dias'
    },
    {
      label: 'Período personalizado',
      value: 'personalizado'
    }
  ]

  async function onClearSelectFilter() {
    setNameFilter()
    setStatusFilter(null)
    setInitialDate()
    setFinalDate()
    setSelectedDate()
    setCompanyFilter()
    setRequestFilter(null)
    await handlegetProposals({
      page: 1
    })
  }

  function handleSelectedDate(filterDate) {
    const nowDays = moment().format('YYYY-MM-DD')
    const date30DaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD')
    const date60DaysAgo = moment().subtract(60, 'days').format('YYYY-MM-DD')
    const date90DaysAgo = moment().subtract(90, 'days').format('YYYY-MM-DD')

    if (filterDate === '30 dias') {
      setFinalDate(nowDays)
      setInitialDate(date30DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === '60 dias') {
      setFinalDate(nowDays)
      setInitialDate(date60DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === '90 dias') {
      setFinalDate(nowDays)
      setInitialDate(date90DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === 'personalizado') {
      setHiddenInputs(true)
      setFinalDate()
      setInitialDate()
    } else {
      setFinalDate()
      setInitialDate()
      setHiddenInputs(false)
    }
  }

  useEffect(() => {
    handleSelectedDate(selectedDate)
  }, [selectedDate])

  const handlegetProposals = async payload => {
    setSpint(true)
    const requestPayload = payload || {
      page: pagination.currentPage
    }

    const accessLevel = Cookies.getItem('accessLevel')

    if (accessLevel !== '0') {
      requestPayload.idCompany = Cookies.getItem('companyId')
    }

    const response = await getProposals(requestPayload)

    if (!response) {
      setSpint(false)
      return
    }

    const proposalsWithCompany = []

    await Promise.all(
      (response?.data || []).map(async element => {
        const company = await getCompany({
          id: element.idCompany
        })

        proposalsWithCompany.push({
          ...element,
          companyName: company.companyName
        })
        return element
      })
    )

    setPagination({
      currentPage: response.currentPage,
      count: response.count
    })

    const sortedProposals = proposalsWithCompany.sort((a, b) => {
      return new Date(b.dtCreated) - new Date(a.dtCreated)
    })

    setProposals(sortedProposals)
    setSpint(false)
  }

  const handlePagination = async page => {
    requestFilter.page = page
    await handlegetProposals({
      page,
      ...requestFilter
    })
  }

  useEffect(() => {
    listMatriz().then(companies => {
      const uniqueCompanies = companies.map(company => ({
        idCompany: company.id,
        companyName: company.companyName
      }))

      setUniqueKey(uniqueCompanies)
    })
  }, [])

  const handleFilter = async () => {
    const payload = {}

    if (selectedDate) {
      if (selectedDate === 'personalizado') {
        if (initialDate && finalDate) {
          if (moment(initialDate.$d).isAfter(finalDate.$d)) {
            messageApi.open({
              type: 'error',
              content: 'Data inicial não pode ser maior que a data final!'
            })
            return
          }
          payload.idCompany = companyFilter
          payload.proposal_status = statusFilter
          payload.startDate = moment(initialDate.$d).format('YYYY-MM-DD')
          payload.endDate = moment(finalDate.$d).format('YYYY-MM-DD')
          payload.name = nameFilter
        } else {
          messageApi.open({
            type: 'error',
            content: 'Data inicial e data final devem esta preenchidos!'
          })
          return
        }
      } else {
        payload.idCompany = companyFilter
        payload.proposal_status = statusFilter
        payload.startDate = initialDate
        payload.endDate = finalDate
        payload.name = nameFilter
      }
    } else {
      payload.idCompany = companyFilter
      payload.proposal_status = statusFilter
      payload.name = nameFilter
    }

    setRequestFilter(payload)
    payload.page = 1

    await handlegetProposals(payload)
  }

  const handleCheckAuth = async () => {
    const token = Cookies.getItem('token')
    if (token) {
      const response = await checkLogin(token)

      if (!response.status) {
        Cookies.removeItem('token')
        return navigate('/login')
      }

      // if (Cookies.getItem('token')) {
      // }
    } else {
      navigate('/login')
    }
  }

  const sentInfoRequested = async (values, element) => {
    setSpint(true)
    const paylaod = {
      id: element.id,
      document: element.cnpj
        ? maskCpfCnpj(element.cnpj)
        : maskCpfCnpj(element.cpf),
      observation: element.observation,
      answer: values.answer
    }

    await sendProposalInfoRequested(paylaod)
    setSpint(false)
    handlegetProposals()
  }

  useEffect(() => {
    handleCheckAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handlegetProposals()
  }, [])

  return (
    <AppLayout currentOption="historic-ec">
      <Spin indicator={antIcon} spinning={spin}>
        {contextHolder}
        <Select
          allowClear
          placeholder="Filtrar por status"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setStatusFilter(e)}
          value={statusFilter}
          onClear={onClearSelectFilter}
          options={accreditationStatusData.map(status => ({
            label: status.label,
            value: status.status
          }))}
        />
        <Select
          allowClear
          placeholder="Filtrar por canal"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setCompanyFilter(e)}
          onClear={onClearSelectFilter}
          value={companyFilter}
          options={
            uniqueKey &&
            uniqueKey.map(company => ({
              label: company.companyName,
              value: company.idCompany
            }))
          }
        />
        <Input
          placeholder="Filtrar por razão social ou nome"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setNameFilter(e.target.value)}
          value={nameFilter}
          onClear={onClearSelectFilter}
        />
        <Select
          allowClear
          placeholder="Filtrar por data de pedido"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setSelectedDate(e)}
          onClear={onClearSelectFilter}
          value={selectedDate}
          options={DynamicSelectedDate.map(element => ({
            label: element.label,
            value: element.value
          }))}
        />

        {hideenInputs && (
          <>
            <DatePicker
              placeholder="Data inicial"
              format={'DD/MM/YYYY'}
              style={{ width: 270, marginTop: '2.5em', marginLeft: '1.4em' }}
              onChange={e => setInitialDate(e)}
              value={initialDate}
              onClear={onClearSelectFilter}
            />

            <DatePicker
              placeholder="Data final"
              format={'DD/MM/YYYY'}
              style={{ width: 270, marginTop: '2.5em', marginLeft: '0.5em' }}
              onChange={e => setFinalDate(e)}
              value={finalDate}
              onClear={onClearSelectFilter}
            />
          </>
        )}

        <Button
          onClick={handleFilter}
          style={{ width: 150, marginLeft: '2em' }}
          type="primary"
        >
          Filtrar
        </Button>

        <Button
          onClick={onClearSelectFilter}
          style={{ width: 150, marginLeft: '1em', marginTop: '2.5em' }}
          type="primary"
        >
          Limpar
        </Button>
        <Pagination
          onChange={handlePagination}
          style={{ marginTop: '2.5em' }}
          pageSize={10}
          showSizeChanger={false}
          defaultCurrent={1}
          total={pagination.count}
          current={pagination.currentPage}
        />
        <AccreditationHistoricComponents.ItemsWrapper>
          {proposals.length > 0 ? (
            proposals.map((element, index) => {
              const findStatus = accreditationStatusData.find(
                status => status.status === element.proposal_status
              )
              if (findStatus) {
                return (
                  <Spin key={index} indicator={antIcon} spinning={spin}>
                    <AccreditationHistoricComponents.Item key={element.id}>
                      <AccreditationHistoricComponents.ItemStatusWrapper>
                        <strong>{element.companyName}</strong>
                      </AccreditationHistoricComponents.ItemStatusWrapper>
                      <br />
                      <AccreditationHistoricComponents.ItemStatusWrapper>
                        {element.id}
                      </AccreditationHistoricComponents.ItemStatusWrapper>
                      <br />
                      <AccreditationHistoricComponents.ItemStatusWrapper>
                        <div className={findStatus.class}>
                          {findStatus.icon} {findStatus.label}
                        </div>
                      </AccreditationHistoricComponents.ItemStatusWrapper>
                      <AccreditationHistoricComponents.ItemInfoWrapper>
                        <div>
                          <strong>{element.cpf ? 'CPF' : 'CNPJ'}</strong>
                        </div>
                        <div>
                          {element.cnpj
                            ? maskCpfCnpj(element.cnpj)
                            : maskCpfCnpj(element.cpf)}
                        </div>
                      </AccreditationHistoricComponents.ItemInfoWrapper>
                      <AccreditationHistoricComponents.ItemInfoWrapper>
                        <div>
                          <strong>
                            {element.cnpj ? 'Razão Social' : 'Nome'}
                          </strong>
                        </div>
                        <div>
                          {element.cnpj
                            ? element.social_name
                            : element.full_name}
                        </div>
                      </AccreditationHistoricComponents.ItemInfoWrapper>
                      <AccreditationHistoricComponents.ItemInfoWrapper>
                        <div>
                          <strong>Data do pedido</strong>
                        </div>
                        <div>
                          {moment(String(element.dtCreated))
                            .subtract('3', 'hour')
                            .format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                      </AccreditationHistoricComponents.ItemInfoWrapper>
                      {element.observation && element.observation !== '' && (
                        <>
                          <AccreditationHistoricComponents.ItemInfoWrapper>
                            <div>
                              <strong>Observação</strong>
                            </div>
                            <div>{element.observation}</div>
                          </AccreditationHistoricComponents.ItemInfoWrapper>
                        </>
                      )}
                      {element.observation &&
                        element.observation !== '' &&
                        element.proposal_status === 'request-update' && (
                          <AccreditationHistoricComponents.ItemInfoWrapper>
                            <Form
                              onFinish={values =>
                                sentInfoRequested(values, element)
                              }
                            >
                              <Form.Item
                                label="Enviar informações solcitadas"
                                name="answer"
                              >
                                <Input.TextArea rows="6" cols="40" />
                              </Form.Item>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Enviar
                                </Button>
                              </Form.Item>
                            </Form>
                          </AccreditationHistoricComponents.ItemInfoWrapper>
                        )}
                    </AccreditationHistoricComponents.Item>
                  </Spin>
                )
              }
            })
          ) : (
            <p>
              Nenhuma proposta encontrada, revise os filtros ou aperte em
              'Limpar' e tente novamente
            </p>
          )}
        </AccreditationHistoricComponents.ItemsWrapper>
      </Spin>
    </AppLayout>
  )
}

export default AccreditationHistoric
